import { createSlice } from "@reduxjs/toolkit";

// Get token from localStorage (for persistence)
const token = localStorage.getItem("token");

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: token || null,
    isAuthenticated: !!token,
  },
  reducers: {
    login: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = true;
      localStorage.setItem("token", action.payload);  // Save token
    },
    logout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem("token");  // Remove token
    },
    profile: (state, action) => {
        state.role=action?.payload?.modify?.role?.role_types
        state.token = action.payload;
        state.isAuthenticated = true;
      },
  },
});

export const { login, logout,profile } = authSlice.actions;
export default authSlice.reducer;
